const mn = {
    "home":"Нүүр",
    "about":"Бидний тухай",
    "projects":"Төслүүд",
    "cooperate":"Хамтран ажиллах",
    "news":"Мэдээ мэдээлэл",
    "hr":"Хүний нөөц",
    "contacts":"Холбоо барих",
    "strip":"Ил уурхайн хөрс хуулалт, олборлолтын ажил",
    "miningSup":"Уул уурхайн туслах ажил",
    "rentalEq":"Тоног төхөөрөмж түрээс",
    "AboutCom":"Компанийн тухай",
    "History":"Засагт Хаан ХХК нь 2008 онд уул уурхайн хайгуул, олборлолтын чиглэлээр үйл ажиллагаагаа эхэлсэн бөгөөд 2011 оноос эхлэн орон сууцны болон үйлдвэрийн барилга байгууламжийн угсралт, хүнд машин механизмын түрээсийн чиглэлээр үйл ажиллагаагаа өргөтгөсөн өдгөө дараах чиглэлээр үйл ажиллагаа явуулж буй оператор компани юм.",
    "totalPro":"Нийт хэрэгжүүлсэн төсөл",
    "totalTehc":"Техникийн нийт хүчин чадал",
    "danger":"Үүсгэн байгуулагдсанаас хойш гарсан осол аваар, хөдөлмөрийн чадвараа алдсан тохиолдол",
    "dangerTotal":"Нийт ажиллаж буй оператор, ажилтнуудын тоо",
    "dangerHuman":"Тухайн орон нутгаас ажиллаж буй ажилчдын нийт ажилтнуудад эзлэх хувь",
    "experEngi":"Инженер техникийн ажилтнуудын ажлын туршлага",
    "Ovoot":"Овоот толгой төсөл",
    "OvootHeader":"Овоот толгой",
    "tsankhi":"Цанхийн баруун уурхайн нүүрс ачилт",
    "caroHis1":"Саусгоби Ресурс Лимитед (Саусгоби) нь Торонто болон Хонконгийн Хөрөнгийн Биржид (TSX: SGQ, HK: 1878) бүртгэлтэй олон нийтэд нээлттэй нүүрс нийлүүлэгч компани бөгөөд Саусгоби компани нь логистикийн дэд бүтэцтэй, арилжааны чадамж бүхий стратегийн ач холбогдолтой нүүрсний орд эзэмшдэг болно. Тус орд нь Өмнөговь аймгийн Гурван тэс суманд орших бөгөөд 9,283 га бүхий талбайд 2037 он хүртэл олборлолт хийх ашиглалтын тусгай зөвшөөрөлтэй.",
    "caroHis1-1":"",
    "caroHis2":"Монголын өмнөд хэсэгт орших Өмнөговь аймагт оршдог дэлхийн хамгийн том ашиглагдаагүй коксжих болон эрчим хүчний нүүрсний ордуудын нэг бөгөөд нийт 2.9 тэрбум тонн нөөцтэй ба үүний дөрөвний нэг нь сайн чанарын коксжих нүүрс бүхий ордтой стратегийн гол орд юм.",
    "caroHis2-1":" ",
    "more":"Дэлгэрэнгүй",
    "name":"Овог, нэр",
    "email":"Имэйл",
    "phone":"Утас",
    "title":"Хүсэлтийн гарчиг",
    "feedbackDeta":"Санал хүсэлтийн дэлгэрэнгүй",
    "feedbackSend":"Санал хүсэлт илгээх",
    "feedbackTitle":"Та манай бүтээгдэхүүн үйлчилгээтэй холбоотой санал хүсэлтээ дараах формоор илгээнэ үү. Манай мэдээллийн ажилтан таны бөглөсөн мэдээллийн дагуу 24 цагийн дотор эргүүлэн холбоо барих болно.",
    "feedbackReq":"Хүсэлт илгээх",
    "contact":"Бидэнтэй холбогдох",
    "centralBnch": "ТӨВ ХАЯГ: Өмнөговь аймаг, Даланзадгад сум 6-р баг, Их уул Шинэ суурьшлын бүс, Спорт цогцолборын баруун хойно Цогжавхлан үйлчилгээний төв 2 давхар 204 тоот",
    "location":"САЛБАР ХАЯГ: Монгол улс, Улаанбаатар хот, Сүхбаатар дүүрэг, 1-р хороо, С.Жамъян гүнгийн гудамж, Хаан даатгал байр, 306 тоот",
    "greeting":"Захирлын мэндчилгээ",
    "introCom":"Компанийн танилцуулга",
    "vision":"Алсын хараа, эрхэм зорилго, үнэт зүйл",
    "currentNews":"Цаг үеийн мэдээлэл",
    "comNews":"Байгууллагын мэдээ",
    "socialRes":"Нийгмийн хариуцлага",
    "humanPolicy":"Хүний нөөцийн бодлого",
    "curriculum":"Сургалтын хөтөлбөр",
    "availavleJob":"Нээлттэй ажлын байр",
    "reverse":"Засагт Хаан ХХК | Бүх эрх хуулиар хамгаалагдсан.",
    "comPhone":"99110583",
    "comEmail":"erdenejargal@zasagt.mn",
    "comFace":"Засагт хаан ХХК",


    "aboutIntro":"ТАНИЛЦУУЛГА",
    "aboutCourse":"Одоогийн үйл ажиллагааны чиглэл",
    "aboutTimeline":"Түүхэн замнал",
    "aboutPro":"ТӨСЛИЙН ТУХАЙ",
    "proOne":"Саусгоби Ресурс Лимитед (Саусгоби) нь Торонто болон Хонконгийн Хөрөнгийн Биржид (TSX: SGQ, HK: 1878) бүртгэлтэй олон нийтэд нээлттэй нүүрс нийлүүлэгч компани бөгөөд Саусгоби компани нь логистикийн дэд бүтэцтэй, арилжааны чадамж бүхий стратегийн ач холбогдолтой нүүрсний орд эзэмшдэг болно. Тус орд нь Өмнөговь аймгийн Гурван тэс суманд орших бөгөөд 9,283 га бүхий талбайд 2037 он хүртэл олборлолт хийх ашиглалтын тусгай зөвшөөрөлтэй.Манай компани 2022 оноос эхлэн Саусгоби Сэндс компанитай хамтран ажиллаж эхэлсэн бөгөөд Овоот Толгой дахь Нармандах болон Наржаргах уурхайд хөрс хуулалт, нүүрс олборлолтын ажлыг хийж гүйцэтгэж байна.",
    "proTwo":"Монголын өмнөд хэсэгт орших Өмнөговь аймагт оршдог дэлхийн хамгийн том ашиглагдаагүй коксжих болон эрчим хүчний нүүрсний ордуудын нэг бөгөөд нийт 2.9 тэрбум тонн нөөцтэй ба үүний дөрөвний нэг нь сайн чанарын коксжих нүүрс бүхий ордтой стратегийн гол орд юм.",
    "proCap":"ТӨСЛИЙН ОДООГИЙН ХҮЧИН ЧАДАЛ",
    "proCapOne":"Засагт Хаан ХХК нь Овоот толгойн ил уурхайд 200 орчим ажилтантай жилийн 7.5 сая.м3 уулын цул олборлох хүчин чадал бүхий утгуурт ачигч болон өөрөө буулгагч машинуудаар үйл ажиллагаа явуулж байна.",
    "proCapTwo":"Эрдэнэс Таван Толгой ХХК-ийн баруун цанхийн уурхайн нүүрс ачилтын ажилд 2020 оноос эхлэн одоог хүртэл ажиллаж баялаг бүтээлцэж байгаа бөгөөд жилийн 4.8 сая.тн нүүрс ачилт хийх хүчин чадалтай.",
    "mechanicTitle":"ДУНД ОВРЫН ТОНОГ ТӨХӨӨРӨМЖ",
    "mechanicTitleSmall":"бага оврын тоног төхөөрөмж",
    "sp1":"1 - 3 сая m3/жил гүйцэтгэх",
    "sp2":"2 - 7 сая m3/жил гүйцэтгэх",
    "spp1":"“Засагт хаан” ХХК нь 1.0 - 3.0 сая.м3/жил хүчин чадал бүхий ил уурхайн хөрс хуулалт, олборлолтын ажлын тоног төхөөрөмжийн хослол санал болгож байна.",
    "spp2":"“Засагт хаан” ХХК нь 2.0 - 7.0 сая.м3/жил хүчин чадал бүхий ил уурхайн хөрс хуулалт, олборлолтын ажлын тоног төхөөрөмжийн хослол санал болгож байна.",
    "spe1":"Ил уурхайн бага оврын тоног төхөөрөмжийн хослол",
    "spe2":"Ил уурхайн дунд оврын тоног төхөөрөмжийн хослол",
    "tech1":"Бульдозер",
    "tech2":"Экскаватор",
    "tech3":"Автосамосвал",
    "tech4":"Утгуурт ачигч",
    "tech5":"Усны машин",
    "tech6":"Автогрейдер",
    "tech7":"Гэрэлт цамхаг",
    "tech8":"Төрөл ",
    "tech9":"Марк",
    "tech10":"Зураг ",
    "tech11":"Тоо",
    "tech12":"Үзүүлэлт",
    "policy":"Хүний нөөцийн бодлого",
    "title1":"Бид ажилтны тасралтгүй суралцах, өсч хөгжих эрмэлзлэлийг идэвхитэй дэмжих замаар ёс зүйтэй, чадварлаг хамт олны бүрдүүлж, бизнесийн урт хугацааны, тогтвортой амжилтыг бий болгоно.",
    "purpose":"Хүний нөөцийн зорилт",
    "title2":`<ul class="list-disc list-outside text-justify leading-8"><li>Ажилтнуудын сурч хөгжих орчинг бүрдүүлэх</li><li>Зах зээлд өрсөлдөхүйц цалин, урамшуулал, хангамж олгох</li><li>Ажилтнууд ёс зүйн хэм хэмжээг баримтлаж, сэтгэл хангалуун ажиллах хамт олны соёлыг хэвшүүлэх</li><li>Мэдлэг, ур чадварт суурилсан мэргэжлийн хамт олныг бүрдүүлэх</li></ul>`,
    "procedure":"Сонгон шалгаруулалтын зарчим ",
    "title3":"Бид ажилд орох хүсэлт гаргасан иргэн бүрд адил, тэгш боломж олгож, ажилтан сонгон шалгаруулалтаа шударга, ил тодоор явуулдаг.",
    "upskill":"Ур чадвар нэмэгдүүлэх сургалт",
    "ohs":"Хөдөлмөрийн эрүүл мэнд аюулгүй байдал (ХЭМАБ)",
    "openings":"Нээлттэй ажлын байрууд"
}   

export default mn